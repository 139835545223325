.light {
  .ant-tooltip-inner {
    color: var(--brownish-grey);
    width: fit-content;
    padding: 0.8rem 1.6rem;
    width: 31.2rem;
  }
  .ant-tooltip {
    font-size: 1.4rem;
    max-width: 3.12rem;
  }
  &_short {
    @extend .light;
    .ant-tooltip-inner {
      width: 25.0rem;
    }
  }
}
.dark {
  .ant-tooltip-inner {
    color: var(--white);
    padding: 0.5rem 1.2rem;
    width: max-content;
    max-width: 45vw;
  }
  &.wrap {
    .ant-tooltip-inner {
      max-width: 400px;
    }
  }
}
