@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  src: url("./Kanit-Regular.ttf");
  src: local("Kanit"), local("Roboto-Regular"),
    url("./Kanit-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  font-style: bold;
  font-weight: 500;
  src: url("./Kanit-Regular.ttf");
  src: local("Kanit"), local("Roboto-Regular"),
    url("./Kanit-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Roboto-Regular.ttf");
  src: local("Kanit"), local("Roboto-Regular"),
    url("./Roboto-Regular.ttf") format("truetype");
  font-display: swap;
}
